import React, { createContext, useContext, useState } from 'react';

const AppState = createContext(undefined);

export function AppStateProvider({ children }) {
  const [parameters, setParameters] = useState({});

  return (
    <AppState.Provider
      value={{
        parameters,
        setParameters,
      }}
    >
      {children}
    </AppState.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppState);

  if (!context) {
    throw new Error('useAppState must be used inside a `AppStateProvider`');
  }

  return context;
}
