import { DEFAULT_LAYOUT } from '../config/index.js';

export const isDynamicPage = (page) => {
  return /:/.test(page.slug);
};

export const findAllDynamicPages = (pages) => {
  return pages.filter((page) => {
    return isDynamicPage(page.data);
  });
};

export const isSlugCorrespondingToDynamicPage = ({ page, slug }) => {
  const regExp = new RegExp(
    `^${page.data.slug.replace(/\//g, '\\/').replace(/:.+$/, '.+')}$`
  );

  return regExp.test(slug);
};

export const getDynamicPageKey = (page) => {
  return page.data.slug.replace(/.+\:(.+)/, '$1');
};

/**
 * @example
 * getDynamicPageDocumentUid({
 *   page,
 *   slug: '/article/my-slug'
 * })
 * // returns 'my-slug'
 */
export const getDynamicPageDocumentUid = ({ page, slug }) => {
  return slug.replace(page.data.slug.replace(/:.+$/, ''), '');
};

export const generateDynamicPageKeyRegExp = (key) => {
  return new RegExp(`\:${key}`);
};

export const findDynamicPage = ({
  pages,
  key,
  lang = DEFAULT_LAYOUT.locale.locale,
}) => {
  return pages.find((page) => {
    return (
      lang === page.lang &&
      generateDynamicPageKeyRegExp(key).test(page.data.slug)
    );
  });
};
