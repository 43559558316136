import 'vanilla-cookieconsent';
import { PrismicPreview } from '@prismicio/next';
import { PrismicProvider } from '@prismicio/react';
import { useEffect } from 'react';
import Link from 'next/link';
import NextApp from 'next/app';
import React from 'react';

import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { createClient, repositoryName } from '../prismicio';

import CookieConsent from '../components/CookieConsent';

import { generateLinkResolver } from '../helpers/link-resolver';
import { useParametersStorage } from '../helpers/utm-decoration';
import { AppStateProvider } from '../helpers/context.js';

import '../styles/index.css';

function ParametersStorage({ children }) {
  useParametersStorage();

  return children;
}

function App({ Component, pageProps, global }) {
  if (pageProps.locale) {
    useEffect(() => {
      document.documentElement.setAttribute(
        'lang',
        pageProps.locale.current.shortCode
      );
    }, [pageProps.locale.current]);
  }

  return (
    <AppStateProvider>
      <ParametersStorage>
        <PrismicProvider
          linkResolver={generateLinkResolver({
            pages: global.pages,
          })}
          internalLinkComponent={({ href, children, ...props }) => (
            <Link href={href} {...props}>
              {children}
            </Link>
          )}
        >
          <PrismicPreview repositoryName={repositoryName}>
            <Component {...pageProps} />
            <CookieConsent pageProps={pageProps} />
          </PrismicPreview>
        </PrismicProvider>
      </ParametersStorage>
    </AppStateProvider>
  );
}

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  const client = createClient();

  const pages = await client.getAllByType('page', { lang: '*' });
  const articles = await client.getAllByType('article', { lang: '*' });
  const events = await client.getAllByType('event', { lang: '*' });

  return {
    ...appProps,

    global: {
      pages: pages.map((page) => {
        return {
          id: page.id,
          lang: page.lang,
          data: {
            slug: page.data.slug,
          },
        };
      }),
      articles: articles.map((article) => {
        return {
          id: article.id,
          uid: article.uid,
          lang: article.lang,
          data: {},
        };
      }),
    },
    events: events.map((event) => {
      return {
        id: event.id,
        uid: event.uid,
        lang: event.lang,
        data: {},
      };
    }),
  };
};

export default App;
