import { useEffect } from "react";

import { useAppState } from "./context.js";

const DOMAINS_TO_DECORATE = ["cite-histoire.placeminute.com"];

const PARAMETERS = [
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

/**
 * @see {@link https://stackoverflow.com/questions/831030/}
 */
export const getQueryParam = (name) => {
  const split =
    typeof window !== "undefined"
      ? new RegExp("[?&]" + encodeURIComponent(name) + "=([^&]*)").exec(
          window.location.search
        )
      : null;

  if (split) {
    return decodeURIComponent(split[1]);
  }

  const sessionStorageValue =
    typeof window !== "undefined" ? window.sessionStorage.getItem(name) : null;

  if (sessionStorageValue) {
    return sessionStorageValue;
  }
};

export const storeQueryParameters = (key, value) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(key, value);
  }
};

export const decorateUrl = (input, parameters) => {
  let href = `${input}`;

  for (
    var domainIndex = 0;
    domainIndex < DOMAINS_TO_DECORATE.length;
    domainIndex++
  ) {
    if (
      href.indexOf(DOMAINS_TO_DECORATE[domainIndex]) > -1 &&
      href.indexOf("#") === -1
    ) {
      href = href.indexOf("?") === -1 ? href + "?" : href + "&";

      var collectedParameters = [];

      for (var queryIndex = 0; queryIndex < PARAMETERS.length; queryIndex++) {
        const value = parameters[PARAMETERS[queryIndex]];

        if (value) {
          collectedParameters.push(`${PARAMETERS[queryIndex]}=${value}`);
        }
      }

      return `${href}${collectedParameters.join("&")}`;
    }
  }

  return href;
};

export const useParametersStorage = () => {
  const appState = useAppState();

  useEffect(() => {
    const parameters = {};

    for (var queryIndex = 0; queryIndex < PARAMETERS.length; queryIndex++) {
      const key = PARAMETERS[queryIndex];
      const value = getQueryParam(key);

      if (value) {
        parameters[key] = value;

        storeQueryParameters(key, value);
      }
    }

    appState.setParameters(parameters);
  }, []);
};
