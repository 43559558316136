import { useEffect } from 'react';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as marked from 'marked';

let globalCookies;

/** @type {number} */
const COOKIE_CONSENT_REVISION = 2;

/**
 * @typedef {'necessary_cookies'|'preferences_cookies'|'statistics_cookies'|'marketing_cookies'} CookieConsentCategory
 */

/**
 * @enum {CookieConsentCategory}
 * @type {Object.<string, CookieConsentCategory>}
 */
export const COOKIE_CONSENT_CATEGORY = {
  /**
   * Strictly necessary cookies — These cookies are essential for you to browse
   * the website and use its features, such as accessing secure areas of the
   * site. Cookies that allow web shops to hold your items in your cart while
   * you are shopping online are an example of strictly necessary cookies. These
   * cookies will generally be first-party session cookies. While it is not
   * required to obtain consent for these cookies, what they do and why they are
   * necessary should be explained to the user.
   * @see {@link https://gdpr.eu/cookies/}
   */
  NECESSARY: 'necessary_cookies',

  /**
   * Preferences cookies — Also known as “functionality cookies,” these cookies
   * allow a website to remember choices you have made in the past, like what
   * language you prefer, what region you would like weather reports for, or
   * what your user name and password are so you can automatically log in.
   * @see {@link https://gdpr.eu/cookies/}
   */
  PREFERENCES: 'preferences_cookies',

  /**
   * Statistics cookies — Also known as “performance cookies,” these cookies
   * collect information about how you use a website, like which pages you
   * visited and which links you clicked on. None of this information can be
   * used to identify you. It is all aggregated and, therefore, anonymized.
   * Their sole purpose is to improve website functions. This includes cookies
   * from third-party analytics services as long as the cookies are for the
   * exclusive use of the owner of the website visited.
   * @see {@link https://gdpr.eu/cookies/}
   */
  STATISTICS: 'statistics_cookies',

  /**
   * Marketing cookies — These cookies track your online activity to help
   * advertisers deliver more relevant advertising or to limit how many times
   * you see an ad. These cookies can share that information with other
   * organizations or advertisers. These are persistent cookies and almost
   * always of third-party provenance.
   * @see {@link https://gdpr.eu/cookies/}
   */
  MARKETING: 'marketing_cookies',
};

/**
 * @type {CookieConsentCategory[]}
 */
export const COOKIE_CONSENT_CATEGORIES = [
  COOKIE_CONSENT_CATEGORY.NECESSARY,
  COOKIE_CONSENT_CATEGORY.PREFERENCES,
  COOKIE_CONSENT_CATEGORY.STATISTICS,
  COOKIE_CONSENT_CATEGORY.MARKETING,
];

/**
 * Available Google Tag Manager cookie consent keys:
 *  - ad_storage
 *  - analytics_storage
 *  - functionality_storage
 *  - personalization_storage
 *  - security_storage
 */
const GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP = {
  [COOKIE_CONSENT_CATEGORY.PREFERENCES]: 'personalization_storage',
  [COOKIE_CONSENT_CATEGORY.STATISTICS]: 'analytics_storage',
  [COOKIE_CONSENT_CATEGORY.MARKETING]: 'ad_storage',
};

const generateCookieNameList = (input) => {
  return input.join('<br/>');
};

export const markdownToHtml = (input) => {
  const renderer = new marked.Renderer();

  renderer.link = (href, title, text) => {
    return `<a href="${href}" target="_blank" rel="noopener noreferrer"${
      title ? ` title="${title}"` : ''
    }>${text}</a>`;
  };

  return marked.parseInline(input, {
    renderer,
  });
};

const updateGoogleTagManagerConsentState = () => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    // @ts-ignore
    window.dataLayer.push(arguments);
  }

  const currentCookieConsentAcceptedCategories = globalCookies.categories;

  const consentState = {};

  Object.keys(GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP).forEach((key) => {
    consentState[GOOGLE_TAG_MANAGER_COOKIES_CONSENT_KEY_MAP[key]] =
      currentCookieConsentAcceptedCategories.includes(key)
        ? 'granted'
        : 'denied';
  });

  gtag('consent', 'update', consentState);
  gtag('event', 'consentUpdate');

  const consentEvent = {
    event: 'consent',
  };

  for (const category of COOKIE_CONSENT_CATEGORIES) {
    consentEvent[category] = currentCookieConsentAcceptedCategories.includes(
      category
    )
      ? 1
      : 0;
  }

  gtag(consentEvent);
};

const handleCookieConsentUpdate = (cookies) => {
  globalCookies = cookies;

  if (!(globalCookies && globalCookies.categories)) {
    return;
  }

  updateGoogleTagManagerConsentState();
};

export default function CookieConsent({ pageProps }) {
  useEffect(() => {
    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        current_lang: pageProps?.locale?.current?.shortCode,
        autoclear_cookies: true,
        cookie_expiration: 365,
        // @ts-ignore
        cookie_name: 'cookie_consent',
        delay: 0,
        revision: COOKIE_CONSENT_REVISION,
        cookie_path: '/',
        hide_from_bots: true,

        /**
         * @example
         * <script type="text/plain" data-src="<path>/analytics.js" data-cookiecategory="analytics" defer />
         */
        page_scripts: true,

        gui_options: {
          consent_modal: {
            layout: 'box',
            position: 'bottom right',
            transition: 'slide',
          },
          settings_modal: {
            layout: 'box',
            transition: 'slide',
          },
        },

        onFirstAction(cookies) {
          handleCookieConsentUpdate(cookies);
        },

        onAccept(cookies) {
          handleCookieConsentUpdate(cookies);
        },

        onChange(cookies) {
          handleCookieConsentUpdate(cookies);
        },

        languages: {
          fr: {
            consent_modal: {
              title: 'Gérer les cookies',
              // description: `Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Voir notre <a href="#privacy-policy" class="cc-link">politique de confidentialité</a>`,
              description: `Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui.`,
              primary_btn: {
                text: 'Tout accepter',
                role: 'accept_all',
              },
              secondary_btn: {
                text: 'Personnaliser mes choix',
                role: 'settings',
              },
              revision_message:
                '<br><br>Cher utilisateur, les termes et conditions ont changé depuis votre dernière visite.',
            },
            settings_modal: {
              title: 'Préférences',
              save_settings_btn: 'Sauvegarder les paramètres',
              accept_all_btn: 'Tout accepter',
              reject_all_btn: 'Tout refuser',
              close_btn_label: 'Fermer',
              cookie_table_headers: [
                {
                  cookieNames: 'Noms des cookies',
                },
                {
                  partner: 'Partenaire',
                },
                {
                  purpose: 'Usage',
                },
              ],
              blocks: [
                {
                  title: 'Utilisation des cookies',
                  // description:
                  //   'Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Voir notre <a href="#" class="cc-link">Politique de confidentialité</a>',
                  description:
                    'Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui.',
                },
                {
                  title: 'Cookies strictement nécessaires',
                  description:
                    'Ces cookies sont essentiels au bon fonctionnement de notre site Web. Sans ces cookies, le site Web ne peut pas fonctionner correctement.',
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.NECESSARY,
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: [
                    {
                      cookieNames: generateCookieNameList(['cookie_consent']),
                      partner: '',
                      purpose: markdownToHtml(
                        `Cookie utilisé pour enregistrer les préférences d'utilisation des cookies du site web ${process.env.HOST}.`
                      ),
                    },
                  ],
                },
                // {
                //   title: 'Cookies de préférences',
                //   description:
                //     "Ces cookies permettent à ce site web de se souvenir des choix que vous avez faits dans le passé, comme la langue que vous préférez, la région pour laquelle vous souhaitez recevoir des bulletins météorologiques ou votre nom d'utilisateur et votre mot de passe afin que vous puissiez vous connecter automatiquement.",
                //   toggle: {
                //     value: COOKIE_CONSENT_CATEGORY.PREFERENCES,
                //     enabled: true,
                //     readonly: false,
                //   },
                // },
                {
                  title: 'Cookies statistiques',
                  description:
                    'Ces cookies recueillent des informations sur la façon dont vous utilisez le site Web, les pages que vous visitez et les liens sur lesquels vous cliquez.',
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.STATISTICS,
                    enabled: true,
                    readonly: false,
                  },
                  cookie_table: [
                    {
                      cookieNames: generateCookieNameList([
                        'gtm_id_ga',
                        '_ga',
                        '_gid',
                        'CONSENT',
                        'cid',
                        'NID',
                        'OGP',
                        'OGPC',
                        'OTZ',
                        '_gcl_au',
                        'ANID',
                        'APISID',
                        'SAPISID',
                        'SIDCC',
                        'SSID',
                        '1P_JAR',
                        'HSID',
                        'S',
                        'DV',
                        'SEARCH_SAMESITE',
                        'SID',
                        '__Secure-1PAPISID',
                        '__Secure-1PSID',
                        '__Secure-1PSIDCC',
                        '__Secure-3PAPISID',
                        '__Secure-3PSID',
                        '__Secure-3PSIDCC',
                      ]),
                      partner: markdownToHtml(
                        'Google, voir [les règles de confidentialité de Google](https://policies.google.com/privacy).'
                      ),
                      purpose: markdownToHtml(
                        'Nous utilisons les services de Google pour analyser la façon dont les visiteurs utilisent notre site Web.'
                      ),
                    },
                    {
                      cookieNames: generateCookieNameList([
                        '_hjSessionUser*',
                        '_hjSession*',
                        '_hjClosedSurveyInvites',
                        '_hjDonePolls',
                        '_hjMinimizedPolls',
                        '_hjShownFeedbackMessage',
                        '_hjSessionTooLarge',
                        '_hjSessionRejected',
                        '_hjSessionResumed',
                        '_hjid',
                        '_hjRecordingLastActivity',
                        '_hjTLDTest',
                        '_hjUserAttributesHash',
                        '_hjCachedUserAttributes',
                        '_hjLocalStorageTest',
                        '_hjIncludedInPageviewSample',
                        '_hjIncludedInSessionSample',
                        '_hjAbsoluteSessionInProgress',
                        '_hjFirstSeen',
                        '_hjViewportId',
                        '_hjRecordingEnabled',
                      ]),
                      partner: markdownToHtml(
                        'Hotjar, voir [la politique de confidentialité de Hotjar](https://help.hotjar.com/hc/en-us/sections/360007966773-Privacy) et [les informations sur les cookies de Hotjar](https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information).'
                      ),
                      purpose: markdownToHtml(
                        'Nous utilisons Hotjar pour analyser le comportement des visiteurs sur notre site Web.'
                      ),
                    },
                  ],
                },
                {
                  title: 'Cookies de marketing',
                  description:
                    "Ces cookies suivent votre activité en ligne afin de vous partager des publicités plus pertinentes ou pour limiter le nombre de fois où vous voyez une publicité. Ces cookies peuvent partager ces informations avec d'autres organisations ou annonceurs.",
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.MARKETING,
                    enabled: true,
                    readonly: false,
                  },
                  cookie_table: [
                    {
                      cookieNames: [],
                      partner: markdownToHtml(
                        'Clearbit, voir [la politique de confidentialité de Clearbit](https://clearbit.com/privacy).'
                      ),
                      // 'Clearbit, see [Clearbit’s privacy policy](https://clearbit.com/privacy).',
                      purpose: markdownToHtml(
                        'Nous utilisons Clearbit pour le reciblage publicitaire.'
                      ),
                      // en: 'We use Clearbit for retargeting ads.',
                    },
                    {
                      cookieNames: generateCookieNameList([
                        'IDEDSID',
                        '__ar_v4',
                      ]),
                      partner: markdownToHtml(
                        'Google Ads, voir [les règles de confidentialité de Google](https://policies.google.com/privacy).'
                      ),
                      purpose: markdownToHtml(
                        'Nous utilisons les services de Google Ads pour suivre les conversions et les visites de notre site Web faites depuis des publicités.'
                      ),
                    },
                    {
                      cookieNames: generateCookieNameList([
                        'lang',
                        'sdsc',
                        'lms_ads',
                        'lms_analytics',
                        'lidc',
                        'liap',
                        'AnalyticsSyncHistory',
                        'UserMatchHistory',
                        'bcookie',
                        'li_mc',
                        'AMCV_*',
                      ]),
                      partner: markdownToHtml(
                        'LinkedIn Ads, voir [la politique de confidentialité de LinkedIn Ads](https://www.linkedin.com/legal/sas-terms).'
                      ),
                      purpose: markdownToHtml(
                        'Nous utilisons LinkedIn Ads pour le reciblage publicitaire.'
                      ),
                    },
                  ],
                },
              ],
            },
          },
          en: {
            consent_modal: {
              title: 'Manage cookies',
              // description: `Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Voir notre <a href="#privacy-policy" class="cc-link">politique de confidentialité</a>`,
              description: `Our website uses essential cookies to ensure its proper functioning and tracking cookies to understand how you interact with it.`,
              primary_btn: {
                text: 'Accept all',
                role: 'accept_all',
              },
              secondary_btn: {
                text: 'Customize my choices',
                role: 'settings',
              },
              revision_message:
                '<br><br>Dear user the terms and conditions have changed since your last visit.',
            },
            settings_modal: {
              title: 'Preferences',
              save_settings_btn: 'Save settings',
              accept_all_btn: 'Accept all',
              reject_all_btn: 'Refuse all',
              close_btn_label: 'Close',
              cookie_table_headers: [
                {
                  cookieNames: 'Cookies names',
                },
                {
                  partner: 'Partner',
                },
                {
                  purpose: 'Usage',
                },
              ],
              blocks: [
                {
                  title: 'Use of cookies',
                  // description:
                  //   'Notre site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Voir notre <a href="#" class="cc-link">Politique de confidentialité</a>',
                  description:
                    'Our website uses essential cookies to ensure its proper functioning and tracking cookies to understand how you interact with it.',
                },
                {
                  title: 'Strictly necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of our website. Without these cookies, the website cannot function properly.',
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.NECESSARY,
                    enabled: true,
                    readonly: true,
                  },
                  cookie_table: [
                    {
                      cookieNames: generateCookieNameList(['cookie_consent']),
                      partner: '',
                      purpose: markdownToHtml(
                        `Cookie used to save the website's cookie usage preferences of ${process.env.HOST}.`
                      ),
                    },
                  ],
                },
                // {
                //   title: 'Cookies de préférences',
                //   description:
                //     "Ces cookies permettent à ce site web de se souvenir des choix que vous avez faits dans le passé, comme la langue que vous préférez, la région pour laquelle vous souhaitez recevoir des bulletins météorologiques ou votre nom d'utilisateur et votre mot de passe afin que vous puissiez vous connecter automatiquement.",
                //   toggle: {
                //     value: COOKIE_CONSENT_CATEGORY.PREFERENCES,
                //     enabled: true,
                //     readonly: false,
                //   },
                // },
                {
                  title: 'Statistical cookies',
                  description:
                    'These cookies collect information about how you use the website, the pages you visit and the links you click.',
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.STATISTICS,
                    enabled: true,
                    readonly: false,
                  },
                  cookie_table: [
                    {
                      cookieNames: generateCookieNameList([
                        'gtm_id_ga',
                        '_ga',
                        '_gid',
                        'CONSENT',
                        'cid',
                        'NID',
                        'OGP',
                        'OGPC',
                        'OTZ',
                        '_gcl_au',
                        'ANID',
                        'APISID',
                        'SAPISID',
                        'SIDCC',
                        'SSID',
                        '1P_JAR',
                        'HSID',
                        'S',
                        'DV',
                        'SEARCH_SAMESITE',
                        'SID',
                        '__Secure-1PAPISID',
                        '__Secure-1PSID',
                        '__Secure-1PSIDCC',
                        '__Secure-3PAPISID',
                        '__Secure-3PSID',
                        '__Secure-3PSIDCC',
                      ]),
                      partner: markdownToHtml(
                        'Google, see [Google Privacy Policy](https://policies.google.com/privacy).'
                      ),
                      purpose: markdownToHtml(
                        'We use Google services to analyze how visitors use our website.'
                      ),
                    },
                  ],
                },
                {
                  title: 'Cookies de marketing',
                  description:
                    'These cookies track your online activity in order to share more relevant ads with you or to limit the number of times you see an ad. These cookies may share this information with other organizations or advertisers.',
                  toggle: {
                    value: COOKIE_CONSENT_CATEGORY.MARKETING,
                    enabled: true,
                    readonly: false,
                  },
                  cookie_table: [
                    {
                      cookieNames: generateCookieNameList([
                        'IDEDSID',
                        '__ar_v4',
                      ]),
                      partner: markdownToHtml(
                        "Google Ads, see [Google's privacy policy](https://policies.google.com/privacy)."
                      ),
                      purpose: markdownToHtml(
                        'We use Google Ads services to track conversions and visits to our website from ads.'
                      ),
                    },
                  ],
                },
              ],
            },
          },
        },
      });
    }
  }, []);

  if (pageProps.locale) {
    useEffect(() => {
      if (window.CookieConsentApi) {
        window.CookieConsentApi.updateLanguage(
          pageProps.locale.current.shortCode,
          true
        );
      }
    }, [pageProps.locale.current]);
  }

  return null;
}
