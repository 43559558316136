import * as prismicH from "@prismicio/helpers";
import { DEFAULT_LAYOUT, LOCALES } from "../config/index.js";
import {
  findDynamicPage,
  generateDynamicPageKeyRegExp,
  isDynamicPage,
} from "./dynamic-page.js";
import { normalizeSlug } from "./normalize-slug.js";

export const generateLinkResolver = ({ pages }) => {
  /**
   * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
   *
   * @type {prismicH.LinkResolverFunction}
   */
  return (doc) => {
    if (!doc) {
      return null;
    }

    const locale = LOCALES.find((item) => item.locale === doc.lang);

    if (doc.type === "page") {
      let data = doc.data;

      if (!data) {
        const foundDoc = pages.find((page) => {
          return page.id === doc.id;
        });

        if (foundDoc) {
          data = foundDoc.data;
        }
      }

      if (data && data.slug) {
        if (isDynamicPage(data)) {
          return null;
        }

        const pathPrefix =
          locale?.shortName !== DEFAULT_LAYOUT.locale.shortName &&
          data.slug !== locale?.pathPrefix
            ? locale?.pathPrefix
            : "";

        return normalizeSlug(`${pathPrefix}${data.slug}`);
      }
    }

    if (doc.type === "article") {
      if (doc.uid) {
        const dynamicPage = findDynamicPage({
          pages,
          key: "article",
          lang: locale?.locale,
        });

        if (dynamicPage) {
          const pathPrefix =
            locale?.shortName !== DEFAULT_LAYOUT.locale.shortName
              ? locale?.pathPrefix
              : "";

          const slug = normalizeSlug(
            dynamicPage.data.slug.replace(
              generateDynamicPageKeyRegExp("article"),
              doc.uid
            )
          );

          return `${pathPrefix}${slug}`;
        }

        return null;
      }
    }

    return "/";
  };
};
