export const LOCALES = [
  {
    title: 'Français',
    shortName: 'FR',
    locale: 'fr-fr',
    shortCode: 'fr',
    pathPrefix: '/',
    index: true,
  },
  {
    title: 'English',
    shortName: 'EN',
    locale: 'en-us',
    shortCode: 'en',
    pathPrefix: '/en',
    index: true,
  },
];

export const DEFAULT_LAYOUT = {
  locale: LOCALES[0],
};

export const DEFAULT_LOCALE = LOCALES[0];
